import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo.webp';
import { ABOUT_ROUTE, COMMERCE_ROUTE, CORPORATE_ROUTE, LANDING_ROUTE } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { isBurgerActive, isFormActive, setBurgerClose, setisMenuClose, setisMenuToggle } from '../../redux/CalculatorForm/CalculatorFormSlice';
import './Header.css'; 
import { setIsClose } from '../../redux/ShortCallbackFormSlice/ShortCallbackFormSlice';

const MenuButton = ({ onClick, isOpen }) => (
  <button className={`menu ${isOpen ? 'opened' : ''}`} aria-label="Главное меню" onClick={onClick}>
    <svg width="40" height="40" viewBox="0 0 100 100">
      <path
        className={`line line1 ${isOpen ? 'opened' : ''}`}
        d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
      />
      <path className={`line line2 ${isOpen ? 'opened' : ''}`} d="M 20,50 H 80" />
      <path
        className={`line line3 ${isOpen ? 'opened' : ''}`}
        d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
      />
    </svg>
  </button>
);

const Header = () => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state) => state.calculatorForm.isMenuOpen)
  const location = useLocation();

  useEffect(() => {
    dispatch(setisMenuClose())
    dispatch(setBurgerClose())
  }, [location, dispatch]);


  const handleClick = () => {
    dispatch(isFormActive());
  };

  const handleMenuToggle = () => {
    dispatch(setisMenuToggle())
    dispatch(isBurgerActive()); 
    dispatch(setIsClose())
  };

  const handleCloseMenu = () => {
    dispatch(setisMenuClose())
  }

  return (
    <div className="header__fixed">
      <header className="header container">
        <NavLink to={'/'}>
          <img className="header__logo" src={logo} alt="Логотип" />
        </NavLink>
        <nav className={`header__nav ${isMenuOpen ? 'active' : ''}`}>
          <NavLink onClick={handleCloseMenu} className="header__nav-link text-base-sb" to={LANDING_ROUTE}>
            Лендінг
          </NavLink>
          <NavLink onClick={handleCloseMenu} className="header__nav-link text-base-sb" to={CORPORATE_ROUTE}>
            Корпоратив
          </NavLink>
          <NavLink onClick={handleCloseMenu} className="header__nav-link text-base-sb" to={COMMERCE_ROUTE}>
            Інтернет-магазин
          </NavLink>
          <NavLink onClick={handleCloseMenu} className="header__nav-link text-base-sb" to={ABOUT_ROUTE}>
            Про нас
          </NavLink>
        </nav>
        <div className="header__buttons">
          <div className="support__button"></div>
          <button onClick={handleClick} className="header__button"></button>
          <MenuButton onClick={handleMenuToggle} isOpen={isMenuOpen} />
        </div>
      </header>
    </div>
  );
};

export default Header;
