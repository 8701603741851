import { COMMERCE_ROUTE, CORPORATE_ROUTE, LANDING_ROUTE } from "../../../utils/constants";
import Card from "./Card/Card";
import './MainServices.css'


function MainServices() {
    return (
        <div className="main__services container">
            <div className="service__ellipse"></div>
            <h2 className="text-title-4">Наші послуги</h2>
            <div className="main__cards-list">
            <Card title={"Лендінг"} description={"Лендінг - це невеликий веб сайт, що складається з 1 сторінки. Найкраще підійде для презентації та продажу одного конкретного продукту чи послуги."} term={"Термін виконання: від 2х тижнів"} price={"Ціна: від 500$"} link={LANDING_ROUTE}/>
            <Card title={"Корпоратив"} description={"Корпоратив - це сайт компанії, що складається з кількох сторінок. Найкраще підійде для розкриття переваг, позиціювання, залучення нових клієнтів і лідогенерації."} term={"Термін виконання: від 3х тижнів"} price={"Ціна: від 1000$"} link={CORPORATE_ROUTE}/>
            <Card title={"Інтернет - магазин"} description={"Інтернет - магазин - це сайт, що містить багато товарів, їх сортування, кошик і оформлення замовлення. Найкраще підійде для продажу багатьох різних товарів."} term={"Термін виконання: від 4х тижнів"} price={"Ціна: від 2500$"} link={COMMERCE_ROUTE}/>
            </div>
        </div>
    )
}

export default MainServices;