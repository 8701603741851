import { NavLink } from "react-router-dom";
import {
  COMMERCE_ROUTE,
  CORPORATE_ROUTE,
  LANDING_ROUTE,
} from "../../utils/constants";
import "./Footer.css";
import tgIcon from "../../assets/icons/tg-icon.webp";
import viberIcon from "../../assets/icons/viber-icon.webp";
import instIcon from "../../assets/icons/inst-icon.webp";
import ttIcon from "../../assets/icons/tt-icon.webp";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <div className="footer__top">
          <ul className="footer__top-list">
            <NavLink
              to={LANDING_ROUTE}
              className={"footer__top-link text-base-r"}
            >
              Лендінг
            </NavLink>
            <NavLink
              to={CORPORATE_ROUTE}
              className={"footer__top-link text-base-r"}
            >
              Корпоратив
            </NavLink>
            <NavLink
              to={COMMERCE_ROUTE}
              className={"footer__top-link text-base-r"}
            >
              Інтернет - магазин
            </NavLink>
          </ul>
          <ul className="footer__top-contacts">
            <a
              href="mailto:webwizardst@gmail.com"
              className="footer__contact text-base-r"
            >
              webwizardst@gmail.com
            </a>
            <a href="tel:+380997888900" className="footer__contact text-base-r">
              +380-997-888-900
            </a>
          </ul>
        </div>
        <div className="footer__middle">
          <p className="footer__fogo text-title-r">
            <span className="brand-color">W</span>eb{" "}
            <span className="brand-color">W</span>izard Studio
          </p>
          <div className="footer__icons">
            <a target={"_blank"} rel={"noreferrer"} href="https://t.me/webwizardst" className="footer__icon-link">
              <img src={tgIcon} alt="посилання на наш телеграм для зв'язку" className="footer__icon" />
            </a>
            <a rel={"noreferrer"}  target={"_blank"} href="viber://chat?number=%2B380967512070" className="footer__icon-link">
              <img src={viberIcon} alt="посилання на наш вайбер для зв'язку" className="footer__icon" />
            </a>
            <a rel={"noreferrer"}  target={"_blank"} href="https://www.instagram.com/web.wizard.studio/?hl=ru" className="footer__icon-link">
              <img src={instIcon} alt="посилання на наш інстаграм для зв'язку" className="footer__icon" />
            </a>
            <a rel={"noreferrer"}  target={"_blank"} href="https://www.tiktok.com/@web.wizard.studio" className="footer__icon-link">
              <img src={ttIcon} alt="" className="footer__icon" />
            </a>
          </div>
        </div>
        <div className="footer__bottom">
          <p className="footer__copyright text-base-r">
            © copyright 2024 — web-wizard.com.ua. Всі права захищені.
          </p>
          <p className="working__time text-base-r">Працюємо з 10:00 до 20:00</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
