import React, { useState } from 'react';
import './SliderStepResult.css';
import arrowLeft from '../../assets/icons/ArrowLeftIcon.webp'
import arrowRight from '../../assets/icons/ArrowRightIcon.webp'

const SliderStepResult = ({ title, description, imageList }) => {
  const isMobile = window.innerWidth <= 1199;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevButtonClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + imageList.length) % imageList.length);
  };

  const handleNextButtonClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
  };

  return (
    <div className="step__result-slider">
      <div className="slider__result-container">
        <div className="slider__result-controls">
          <h3 className="slider__result-title text-title-3">{title}</h3>
          <p className="slider__result-description text-base-r">{description}</p>
          <div className="step__result-pagination">
            {imageList.map((_, index) => (
              <div
                key={index}
                className={`step__result-pagination-element ${
                  index === currentImageIndex ? 'active' : ''
                }`}
              ></div>
            ))}
          </div>
          <div className="step__result-buttons">
            <button className="step__result-prev-button" onClick={handlePrevButtonClick}>
              <img className='arrow__icon' src={arrowLeft} alt="слайдер назад" />
            </button>
            <button className="step__result-next-button" onClick={handleNextButtonClick}>
            <img className='arrow__icon' src={arrowRight} alt="слайдер назад" />
            </button>
          </div>
        </div>

        <div className="slider__result-current-image">
          <div className="slider__image-container" style={{   transform: `translateX(-${currentImageIndex * (isMobile ? 320 : 693)}px)`
  }}>
            {imageList.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`слайдер фото ${index + 1}`}
                className="slider__image"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SliderStepResult;
