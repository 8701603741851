import React, { useState } from "react";
import CalculatorStep from "./CalculatorStep/CalculatorStep";
import StepsForm from "./StepsForm/StepsForm";
import "./Calculator.css";

const typeSelect = [
  { type: "Лендінг" },
  { type: "Корпоративний" },
  { type: "Інтернет-магазин" },
];

const calculatorTitles = [
  [
    "Який тип сайту потрібен?",
    "Скільки блоків має бути на сайті?",
    "На якій стадії знаходиться сайт?",
    "Чи є зараз сайт у вашої компанії?",
    "Чи є у вас медіаматеріали для сайту?",
    "Чи передбачається багатомовність?",
    "Залишився останній крок...",
  ],
  [
    "Який тип сайту потрібен?",
    "Скільки сторінок має бути на сайті?",
    "На якій стадії знаходиться сайт?",
    "Чи є зараз сайт у вашої компанії?",
    "Чи є у вас медіаматеріали для сайту?",
    "Чи передбачається багатомовність?",
    "Залишився останній крок...",
  ],
  [
    "Який тип сайту потрібен?",
    "Скільки позицій має бути на сайті?",
    "На якій стадії знаходиться сайт?",
    "Чи є зараз сайт у вашої компанії?",
    "Чи є у вас медіаматеріали для сайту?",
    "Чи передбачається багатомовність?",
    "Залишився останній крок...",
  ],
];

function Calculator() {
  const [step, setStep] = useState(0);
  const [type, setType] = useState("");
  const [userChoices, setUserChoices] = useState(
    Array.from({ length: 5 }, () => null)
  );
  const [isStepForm, setIsStepForm] = useState(false);

  const handleTypeSelected = (selectedType) => {
    setType(selectedType);
    setStep((prevStep) => prevStep + 1);
  };

  const handleChoiceSelected = (choice) => {
    setUserChoices((prevChoices) => {
      const newChoices = [...prevChoices];
      newChoices[step - 1] = choice;
      return newChoices;
    });
  };

  const handleNextStep = () => {
    if (step === 5) {
      setStep((prevStep) => prevStep + 1);
      setIsStepForm(true);
      return;
    } else if (step === 0 && !type) {
      return;
    } else if (userChoices[step - 1] === null) {
      return;
    } else if (step === 6) {
      return;
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (step === 1) {
      setType("");
      setStep(0);
      setUserChoices(Array.from({ length: 5 }, () => null));
    } else if (step === 6) {
      setIsStepForm(false);
      setStep((prevStep) => prevStep - 1);
    } else {
      setStep((prevStep) => prevStep - 1);
    }
  };


  return (
    <div className="calculator__container">
      <>
        <h3 className="calculator__title text-title-3">
          {type
            ? calculatorTitles[typeSelect.findIndex((t) => t.type === type)][
                step
              ]
            : calculatorTitles[0][step]}{" "}
        </h3>
        {isStepForm ? (
          <p className="form__description text-base-r">
            Ми зв’яжемося з вами найближчим часом для обговорення вартості і
            нюансів вашого проєкту.
          </p>
        ) : (
          ""
        )}
        <div
          style={{ marginTop: isStepForm ? "0px" : "30px" }}
          className="calculator__questions-wrapper"
        >
          {isStepForm ? (
            <StepsForm answears={userChoices} />
          ) : !type ? (
            typeSelect.map((siteType, idx) => (
              <button
                className="calculator__button-first  text-base-r"
                onClick={() => handleTypeSelected(siteType.type)}
                key={idx}
              >
                {siteType.type}
              </button>
            ))
          ) : (
            <CalculatorStep
              type={type}
              step={step}
              onChoiceSelected={handleChoiceSelected}
              savedAnswers={userChoices}
            />
          )}
        </div>
      </>

      {type ? (
        <div
          style={{ marginTop: isStepForm ? "18px" : "40px" }}
          className="calculator__buttons-wrapper"
        >
          <button
            className="calculator__prev-button text-title-3"
            onClick={handlePrevStep}
          >
            Назад
          </button>
          {isStepForm ? (
            ""
          ) : (
            <button
              className="calculator__next-button text-title-3"
              onClick={handleNextStep}
              disabled={userChoices[step - 1] === null}
            >
              Далі
            </button>
          )}
        </div>
      ) : null}
      <div className="calculator__pagination-wrapper">
        {[...Array(7).keys()].map((index) => (
          <div
            key={index}
            className={`pagination__element ${index <= step ? "active" : ""}`}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default Calculator;
