import Calculator from "./Calculator/Calculator";
import "./MainCalculator.css";

function MainCalculator() {
  return (
    <div className="main_calculator ">
      <div className="main__elipse-5"></div>
      <div className="main__elipse-6"></div>
      <div className="main_calculator-wrapper">
      <div className="calculator__ellipse"></div>
      <div className="main_calculator-texts">
        <h2 className="main_calculator-title text-title-4">Отримайте оцінку вартості</h2>
        <p className="main_calculator-description text-base-r">Ми пропонуємо вам скористатися нашим калькулятором вартості проєкту, щоб отримати оцінку, виходячи з ваших вимог.</p>
        <p className="main_calculator-description text-base-r">Витрати часу: до 5 хвилин.</p>
      </div>
      <Calculator />
      </div>
    </div>
  );
}

export default MainCalculator;
