import Reason from '../../../components/Reason/Reason';
import './CorporateWhyUs.css'

function CorporateWhyUs() {
    return (
        <div className="corporate__why-us">
            <div className="why__us-elipse-one"></div>
            <div className="why__us-elipse-two"></div>
            <h2 className="corporate__why-us-title text-title-4">Чому обирають нас</h2>
            <div className="corporate__why-us-wrapper">
                <Reason count={"oo1"} title={"Участь клієнта в усіх етапах розробки"} description={"Ви будете залучені в розробку, щоб отримати те, що потрібно саме вам."} />
                <Reason count={"oo2"} title={"Індивідуальна розробка"} description={"Чистий та оптимізований код, який відповідає всім сучасним стандартам пошукових систем."} />
                <Reason count={"oo3"} title={"Не використовуємо жодних конструкторів"} description={"Кожен дизайн унікальний, а код пишеться виключно під ваші потреби."} />
                <Reason count={"oo4"} title={"Комплексна аналітика"} description={"Задля чіткого розуміння потреб клієнта в вашому сегменті ринку."} />
                <Reason count={"oo5"} title={"Адаптивний дизайн"} description={"Для комфорту користувача з будь-яким видом девайсу."} />
                <Reason count={"oo6"} title={"Готове бізнес-рішення"} description={"Сайт, який відразу працює на вас, без необхідності щось переробляти."} />
            </div>
        </div>
    )
}

export default CorporateWhyUs;