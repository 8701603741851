import'./AboutUsSliderCard.css'

function AboutUsSliderCard({src, title, description}) {
    return (
        <div className="AboutUsSlider__card">
            <img className='slider__card-image' src={src} alt={`Член команди Веб Візард ${description}`} />
            <div className="AboutUsSlider__card-hover">
            <p className="AboutUsSlider__card-hover-description text-base-r">{description}</p>
            <p className="AboutUsSlider__card-hover-title text-title-3">{title}</p>
            </div>
        </div>
    )
}

export default AboutUsSliderCard