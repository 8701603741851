import "./Step.css";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

function Step({
  src,
  title,
  description,
  reverse,
  alt,
  isItFirstStep,
  stepNumber,
}) {
  const isMobile = window.innerWidth <= 1199;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  return (
    <motion.div
      ref={ref}
      style={{
        flexDirection: isMobile
          ? reverse
            ? "column-reverce"
            : "column"
          : reverse
          ? "row-reverse"
          : "row",
        marginTop: isMobile
          ? isItFirstStep
            ? "0px"
            : "0px"
          : isItFirstStep
          ? "0px"
          : "80px",
      }}
      className="step"
    >
      <motion.img
        src={src}
        alt={alt}
        className="step__image"
        ref={ref}
        variants={{
          hidden: { opacity: 0, x: isMobile ? 0 : reverse ? 250 : -250 },
          visible: { opacity: 1, x: 0 },
        }}
        initial={isMobile ? "visible" : "hidden"}
        animate={mainControls}
        transition={{ duration: .5 }}
      />
      <motion.div
        className="step__texts"
        ref={ref}
        variants={{
          hidden: { opacity: 0, x: reverse ? -150 : 150 },
          visible: { opacity: 1, x: 0 },
        }}
        initial={isMobile ? "visible" : "hidden"}
        animate={mainControls}
        transition={{ duration: 1 }}
      >
        <h3
          className="step__title text-title-3"
          style={{
            marginTop: isMobile
              ? isItFirstStep
                ? "0px"
                : "40px"
              : isItFirstStep
              ? "86px"
              : "66px",
          }}
        >
          {isMobile ? (
            <>
              <span className="brand-color">{stepNumber} </span>
              {title}
            </>
          ) : (
            title
          )}
        </h3>
        <p className="step__description text-base-r">{description}</p>
      </motion.div>
    </motion.div>
  );
}

export default Step;
