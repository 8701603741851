// ModalForm.js

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ModalForm.css";
import closeIcon from "../../assets/icons/close__icon.webp";
import InputMask from "react-input-mask";
import {
  isFormActive,
  setIsFormFailed,
  setIsFormSended,
} from "../../redux/CalculatorForm/CalculatorFormSlice";
import tgIcon from '../../assets/icons/tg-icon.webp'
import viberIcon from '../../assets/icons/viber-icon.webp'
import instIcon from '../../assets/icons/inst-icon.webp'
import ttIcon from '../../assets/icons/tt-icon.webp'

function ModalForm() {
  const dispatch = useDispatch();
  const isActive = useSelector((state) => state.calculatorForm.isFormActive);
  const [userName, setUserName] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [CompanyError, setCompanyError] = useState(false);
  const [PhoneError, setPhoneError] = useState(false);
  const [typeOfSite, setTypeOfSite] = useState("");
  const [activeButton, setActiveButton] = useState(null);

  const handleClose = () => {
    dispatch(isFormActive());
  };

  useEffect(() => {
    const body = document.body;
    if (isActive) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto";
    };
  }, [isActive]);

  const handlePhoneNumberChange = (e) => {
    setUserPhone(e.target.value);
    setPhoneError(false);
  };

  const handleCompanyChange = (e) => {
    setUserCompany(e.target.value);
    setCompanyError(false);
  };

  const handleNameChange = (e) => {
    setUserName(e.target.value);
    setNameError(false);
  };

  const handleMessageChange = (e) => {
    setUserMessage(e.target.value);
  };

  const handleTypeChange = (value) => {
    setTypeOfSite(value);
    setActiveButton(value);
    console.log(typeOfSite);
  };

  const buttonClass = (value) =>
    `modal__form-select-button text-base-s ${
      activeButton === value ? "active" : ""
    }`;

  const sendTelegramMessage = async (message) => {
    const TELEGRAM_BOT_TOKEN = "6532120427:AAEtZkK1QqTSwNYt0mB_VnS4Mf7BdWa62Nk";
    const TELEGRAM_CHAT_ID = "-1002051239118";
    const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: TELEGRAM_CHAT_ID,
          text: message,
        }),
      });

      if (response.ok) {
        dispatch(setIsFormSended(true));
      } else {
        dispatch(setIsFormFailed(true));
      }
    } catch (error) {
      dispatch(setIsFormFailed(true));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let nameErrorFlag = false;
    let companyErrorFlag = false;
    let phoneErrorFlag = false;

    if (userName.length < 1) {
      setNameError(true);
      nameErrorFlag = true;
    } else {
      setNameError(false);
    }
    if (userCompany.length < 1) {
      setCompanyError(true);
      companyErrorFlag = true;
    } else {
      setCompanyError(false);
    }

    if (userPhone && !userPhone.includes("_")) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
      phoneErrorFlag = true;
    }

    if (!nameErrorFlag && !companyErrorFlag && !phoneErrorFlag) {
      const message = `\u{1F408}КОЖАНЫЙ!!!\u{1F408}\nИмя: ${userName}\nКомпания: ${userCompany}\nНомер телефона: ${userPhone}\nТип сайта: ${
        typeOfSite || "Не визначився"
      }\nСообщение: ${
        userMessage || "Без сообщения"
      }`;

      await sendTelegramMessage(message);

      setUserName("");
      setUserCompany("");
      setUserPhone("");
      setUserMessage("");
    }
  };

  return (
    <div className={`modal__form ${isActive ? "active" : ""}`}>
        <div className="modal__form-elipse"></div>
      <img
        onClick={handleClose}
        src={closeIcon}
        alt="закрити форму"
        className="close__button"
      />
      <div className="modal__form-wrapper">
        <div className="modal__form-main">
            <div className="modal__form-main-elipse"></div>
          <h2 className="modal__form-title text-title-6">
            Обговоримо ваш проєкт
          </h2>
          <h2 className="modal__form-description text-base-r">
            Заповніть форму, і ми зв’яжемося з вами найближчим часом
          </h2>
          <form onSubmit={handleSubmit} className="modal__form-form">
            <div className="modal__form-inputs">
              <input
                placeholder="Ім’я"
                value={userName}
                onChange={handleNameChange}
                className={`modal__form-input ${
                  nameError ? "error" : ""
                } text-base-r`}
                type="text"
              />
              <input
                placeholder="Компанія"
                value={userCompany}
                onChange={handleCompanyChange}
                className={`modal__form-input ${
                  CompanyError ? "error" : ""
                } text-base-r`}
                type="text"
              />
              <InputMask
                mask="+380 (99) 999-99-99"
                value={userPhone}
                onChange={handlePhoneNumberChange}
                placeholder="Номер телефону"
                onFocus={(e) => e.target.setSelectionRange(6, 6)}
                onBlur={(e) => {
                  if (e.target.value === "+380 ()") {
                    setUserPhone("");
                  }
                }}
                className={`modal__form-input ${
                  PhoneError ? "error" : ""
                } text-base-r`}
                type="phone"
              />
              <input
                placeholder="Повідомлення (опціонально)"
                className="modal__form-input text-base-r"
                type="text"
                onChange={handleMessageChange}
                value={userMessage}
              />
            </div>
            <p className="modal__form-text text-base-r">
              Який тип сайту вам потрібен?
            </p>
            <div className="modal__form-buttons">
              <div
                onClick={() => handleTypeChange("Лендінг")}
                className={buttonClass("Лендінг")}
              >
                Лендінг
              </div>
              <div
                onClick={() => handleTypeChange("Корпоративний")}
                className={buttonClass("Корпоративний")}
              >
                Корпоративний
              </div>
              <div
                onClick={() => handleTypeChange("Інтернет-магазин")}
                className={buttonClass("Інтернет-магазин")}
              >
                Інтернет-магазин
              </div>
              <div
                onClick={() => handleTypeChange("Не визначився")}
                className={buttonClass("Не визначився")}
              >
                Не визначився
              </div>
            </div>
            <button
              onSubmit={handleSubmit}
              className="modal__form-button text-base-m"
              type="submit"
            >
              Відправити
            </button>
          
          </form>
        </div>
        <div className="modal__form-stick"></div>
        <div className="modal__form-contacts">
            <a href="tel:+380997888900" style={{textAlign: "left", color: "white"}} className="text-title-3">+380 (99) 788 89 00</a>
            <a href="mailto:webwizardst@gmail.com" style={{textAlign: "left", color: "white"}} className="text-title-3">webwizardst@gmail.com</a>
            <div className="modal__form-icons">
              <a rel={"noreferrer"}  target={"_blank"} href="https://t.me/webwizardst" className="modal__form-link">
                <img src={tgIcon} alt="посилання на телеграм" className="modal__form-icon" />
                </a>
                <a rel={"noreferrer"}  target={"_blank"} href="viber://chat?number=%2B380967512070" className="modal__form-link">
                <img src={viberIcon} alt="посилання на вайбер" className="modal__form-icon" />
                </a>
                <a rel={"noreferrer"}  target={"_blank"} href="https://www.instagram.com/web.wizard.studio/?hl=ru" className="modal__form-link">
                <img src={instIcon} alt="посилання на інстаграм" className="modal__form-icon" />
                </a>
                <a rel={"noreferrer"} target={"_blank"} href="https://www.tiktok.com/@web.wizard.studio" className="modal__form-link">
                <img src={ttIcon} alt="посилання на тікток" className="modal__form-icon" />
                </a>
            </div>
        </div>
      </div>
    </div>
  );
}

export default ModalForm;
