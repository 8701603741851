import { useState } from "react";
import { useDispatch } from "react-redux";
import { isFormActive } from "../../redux/CalculatorForm/CalculatorFormSlice";
import Arrow from "./Arrow/Arrow";
import "./LetsTalk.css";

const LetsTalk = () => {
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  const handleMouseOver = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    dispatch(isFormActive());
  };

  return (
    <section
      onClick={handleClick}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
      className="lets__talk"
    >
      <div className="lets__talk-wrapper">
      <h2 className="lets__talk-text text-title-3">
    ПОГОВОРИМО?
    </h2>

        <Arrow isHovered={isHovered} />
      </div>
    </section>
  );
};

export default LetsTalk;
