import Benefite from "./Benefite/Benefite";
import "./MainBenefits.css"
import individualIcon from '../../../assets/icons/individual-icon.webp'
import supportIcon from '../../../assets/icons/support-icon.webp'
import effectiveIcon from '../../../assets/icons/effective-icon.webp'

function MainBenefits() {
    return (
        <div className="main__benefits container">
            <Benefite icon={individualIcon} title={"Ніяких шаблонів"} description={"Дизайн кожного сайту створюється з 0, проектуємо сайт, який вирішує задачі саме Вашого бізнесу."} />
            <Benefite icon={effectiveIcon} title={"Жодних конструкторів"} description={" Тільки сучасні рішення розробки для досягнення максимальної оптимізації та надійності."} />
            <Benefite icon={supportIcon} title={"Підтримка 24/7"} description={"Надаємо безперервну технічну підтримку, щоб ви завжди могли розраховувати на нас."} />

        </div>
    )
}

export default MainBenefits;