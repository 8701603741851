import "./Corporate.css";
import corporateCoverImage from "../../assets/images/corporate-mockup.webp";
import corporateCoverImageMobile from "../../assets/images/CorporateCoverImageMockup-mobile.webp";
import CorporateSteps from "./CorporateSteps/CorporateSteps";
import CorporateWhyUs from "./CorporateWhyUs/CorporateWhyUs";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";

function Corporate() {
  const isMobile = window.innerWidth <= 1199;
  const ref = useRef(null);
  const coverImgRef = useRef(null);
  const isInView = useInView(ref, { once: true });
  const coverControls = useAnimation();
  useEffect(() => {
    if (!isMobile && isInView) {
      coverControls.start("visible");
    }
  }, [isInView, coverControls, isMobile]);

  return (
    <>
      <Helmet>
        <title>Замовити Корпоративний сайт під ключ | Web-Wizard</title>
        <meta
          name="description"
          content="Створення корпоративного сайту (багатосторінкового сайту) під ключ у Києві та Україні ✅ Ціни на розробку корпоротивного сайту у 2024 році 🚀 Замов розробку корпоротивного сайту та отримай результат вже за кілька днів 💰"
        />
        <meta property="og:url" content="https://web-wizard.com.ua/corporate" />
        <link rel="canonical" href="https://web-wizard.com.ua/corporate" />
        <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-M3B2VLR9');        
      `}</script>
      </Helmet>
      <div ref={ref} className="corporate__page">
        <div className="service__page-elipse-one"></div>
        <div className="service__page-elipse-two"></div>
        <div className="corporate__page-elipse-three"></div>
        <div className="corporate__cover">
          <motion.div
            className="corporate__cover-texts"
            ref={coverImgRef}
            variants={{
              hidden: { opacity: 0, x: -350 },
              visible: { opacity: 1, x: 0 },
            }}
            initial={isMobile ? "visible" : "hidden"}
            animate={coverControls}
            transition={{ duration: 0.7 }}
          >
            <h1 className="corporate__cover-title text-title-4">
              Розробка корпоративного сайту з
              <span className="brand-color">W</span>
              eb
              <span className="brand-color">W</span>izard{" "}
            </h1>
            <h2 className="page__price text-title-3">Від 1000$</h2>
            <p className="corporate__cover-descrption text-base-r">
              Розробляючи Corporate website, ми занюрюємось у ваше бізнес
              середовище, щоб зрозуміти вашу компанію, ваші цінності і потреби
              ваших клієнтів.
            </p>
            <p className="corporate__cover-descrption text-base-r">
              Тож, процес розробки обов’язково буде корисним і для вас, адже ви
              побачите свою компанію та продукт з іншої точки зору, в процесі
              поглянете на аналіз веб-сайтів найближчих конкурентів та лідерів у
              вашому секторі, на їх підхід до позиціонування компанії і подачі
              свого продукту. Будете залучені в процес проєктування сайту, який
              сподобається не тільки вам, а, що найголовніше, вашим клієнтам,
              адже ми розуміємо, що саме ви найкраще знаєте, як подати свою
              компанію клієнту. Нижче ми розкриємо магію розробки Сorporate
              website і покажемо як це проходить саме у нас...
            </p>
          </motion.div>
          <motion.img
            className="corporate__cover-image"
            src={isMobile ? corporateCoverImageMobile : corporateCoverImage}
            alt="приклад лендінг сторінки сайту спортивної зали."
            ref={coverImgRef}
            variants={{
              hidden: { opacity: 0, x: 350 },
              visible: { opacity: 1, x: 0 },
            }}
            initial={isMobile ? "visible" : "hidden"}
            animate={coverControls}
            transition={{ duration: 0.7 }}
          />
        </div>
        <CorporateSteps />
        <CorporateWhyUs />
      </div>
      {/* Код Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          title="Google Tag Manager"
          src="https://www.googletagmanager.com/ns.html?id=GTM-M3B2VLR9"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
    </>
  );
}

export default Corporate;
