import "./Reason.css";

function Reason({ title, description, count }) {
  return (
    <div className="reason">
      <div className="reason__header">
      <div className="reason__count text-title-3">{count}</div><h3 className="reason__title text-title-3">{title}</h3>
      </div>
      <p className="reason__description text-base-r">{description}</p>
      </div>
  );
}

export default Reason;
