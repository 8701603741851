import './CorporateStepsProgress.css'

function CorporateStepsProgress() {
    return (
        <div className="corporate__steps-progress">
            <div className="progress__circle text-base-m">001</div>
            <div className="progress__bar text-base-m"></div>
            <div className="progress__circle text-base-m">002</div>
            <div className="progress__bar"></div>
            <div className="progress__circle text-base-m">003</div>
            <div className="progress__bar"></div>
            <div className="progress__circle text-base-m">004</div>
            <div className="progress__bar"></div>
            <div className="progress__circle text-base-m">005</div>
            <div className="progress__bar"></div>
            <div className="progress__circle text-base-m">006</div>
            <div className="progress__bar"></div>
        </div>
    )
}

export default CorporateStepsProgress;