import MainAbout from "./MainAbout/MainAbout";
import MainBenefits from "./MainBenefits/MainBenefits";
import MainCalculator from "./MainCalculator/MainCalculator";
import MainFAQ from "./MainFAQ/MainFAQ";
import "./MainPage.css";
import MainServices from "./MainServices/MainServices";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import MainWhyUs from "./MainWhyUs/MainWhyUs";

function MainPage() {
  const isMobile = window.innerWidth <= 1199;
  const ref = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);

  const isInView = useInView(ref, { once: true });
  const aboutInView = useInView(aboutRef, { triggerOnce: true });
  const servicesInView = useInView(servicesRef, { triggerOnce: true });

  const mainControls = useAnimation();
  const aboutControls = useAnimation();
  const servicesControls = useAnimation();

  useEffect(() => {
    if (!isMobile && isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls, isMobile]);

  useEffect(() => {
    if ( aboutInView) {
      aboutControls.start("visible");
    }
  }, [aboutInView, aboutControls, isMobile]);

  useEffect(() => {
    if (!isMobile && servicesInView) {
      servicesControls.start("visible");
    }
  }, [servicesInView, servicesControls, isMobile]);

  return (
    <>
      <Helmet>
        <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-M3B2VLR9');        
      `}</script>
      </Helmet>
      <div ref={ref} className="page__container">
        <div className="main__elipse-1"></div>
        <div className="main__elipse-3"></div>
        <div className="main__elipse-4"></div>

        <motion.h1
          className="title text-title-r"
          ref={aboutRef}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          initial={ "hidden"}
          animate={aboutControls}
          transition={{ duration: 0.5 }}
        >
          <span className="brand-color">W</span>eb
          <span className="brand-color"> W</span>izard
        </motion.h1>

        <motion.h2
          className="title__description text-title-2"
          ref={aboutRef}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          initial={ "hidden"}
          animate={aboutControls}
          transition={{ duration: 0.7, delay: 0.5 }}
        >
          Створюємо сайти, які працюють на вас
        </motion.h2>

        <MainBenefits />

        <motion.div
          ref={aboutRef}
          variants={{
            hidden: { opacity: 0, y: 150 },
            visible: { opacity: 1, y: 0 },
          }}
          initial={isMobile ? "visible" : "hidden"}
          animate={aboutControls}
          transition={{ duration: 1 }}
        >
          <MainAbout />
        </motion.div>
        <motion.div
          ref={servicesRef}
          variants={{
            hidden: { opacity: 0, y: 150 },
            visible: { opacity: 1, y: 0 },
          }}
          initial={isMobile ? "visible" : "hidden"}
          animate={servicesControls}
          transition={{ duration: 1 }}
        >
          <MainServices />
        </motion.div>
          <MainWhyUs />
        <MainCalculator />
        <MainFAQ />
      </div>
      {/* Код Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          title="Google Tag Manager"
          src="https://www.googletagmanager.com/ns.html?id=GTM-M3B2VLR9"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
    </>
  );
}

export default MainPage;
