import React, { useEffect, useRef } from 'react';
import { createNoise3D } from 'simplex-noise';

const CanvasAnimation = ({height}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const noise = createNoise3D();

    let animationFrameId = null;


    function draw(e) {
      if (!canvas) return;

      let width = canvas.width = window.innerWidth;
      let height = canvas.height = 340;
      let width_half = width * 0;
      let height_half = height * 0.5;

      let grad = ctx.createLinearGradient(-width, 0, width, height);

      let hueA = 240;
      let hueB = 340;
      let colorA = `hsl(${hueA}, 100%, 50%)`;
      let colorB = `hsl(${hueB}, 100%, 50%)`;
      grad.addColorStop(0.33, colorA);
      grad.addColorStop(0.66, colorB);
      grad.addColorStop(1, colorA);

      let time = e * 0.00033;
      ctx.globalAlpha = 0.3; 
      ctx.fillRect(0, 0, width, height);
      ctx.globalAlpha = 0.7;
      ctx.beginPath();
      for (let j = 0; j < 10; j++) {
        let tj = j * (1 / 10);
        let c = Math.cos(tj * Math.PI * 2 + time) * 0.3;
        for (let i = 0; i < 35; i++) {
          let t = i * (1 / 35);
          let n = noise(t, time, c);
          let y = n * height_half + height_half;
          if (y < 0) {
            y = 0;
          } else if (y > height) {
            y = height;
          }
          let x = t * (width + 50) - width_half - 10;
          ctx[i ? 'lineTo' : 'moveTo'](x, y);
        }
      }
      ctx.globalCompositeOperation = 'lighter';
      ctx.strokeStyle = grad;
      ctx.lineWidth = 3;
      ctx.stroke();
      ctx.strokeStyle = `hsla(0, 0%, 100%, 0.8)`;
      ctx.lineWidth = 2;
      ctx.stroke();

      animationFrameId = requestAnimationFrame(draw);
    }

    animationFrameId = requestAnimationFrame(draw);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, []);

  return <canvas ref={canvasRef} style={{ overflow: 'hidden', position: "absolute", top: height, zIndex: 2, transition: "1s", background: "inherit", width: "100%" }} />;
};

export default CanvasAnimation;
