const Arrow = ({ isHovered }) => {
  const isMobile = window.innerWidth <= 1199;

  return !isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="130"
      height="74"
      viewBox="0 0 130 74"
      fill="none"
    >
      <path
        d="M5 32C2.23858 32 -3.21882e-07 34.2386 0 37C3.21882e-07 39.7614 2.23858 42 5 42L5 32ZM128.536 40.5355C130.488 38.5829 130.488 35.4171 128.536 33.4645L96.7157 1.64465C94.7631 -0.307971 91.5973 -0.307971 89.6447 1.64465C87.692 3.59727 87.692 6.7631 89.6447 8.71572L117.929 37L89.6447 65.2843C87.6921 67.2369 87.6921 70.4027 89.6447 72.3553C91.5973 74.308 94.7631 74.308 96.7157 72.3553L128.536 40.5355ZM5 42L125 42L125 32L5 32L5 42Z"
        fill={`${isHovered ? "#1C6EDA" : "white"}`}
        style={{ transition: "fill 0.3s ease" }}
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="30"
      viewBox="0 0 44 30"
      fill="none"
    >
      <path
        d="M2 13C0.895431 13 1.20706e-07 13.8954 0 15C-1.20706e-07 16.1046 0.89543 17 2 17L2 13ZM43.4142 16.4142C44.1953 15.6332 44.1953 14.3668 43.4142 13.5858L30.6863 0.857868C29.9052 0.0768187 28.6389 0.0768185 27.8579 0.857867C27.0768 1.63892 27.0768 2.90525 27.8579 3.68629L39.1716 15L27.8579 26.3137C27.0768 27.0948 27.0768 28.3611 27.8579 29.1421C28.6389 29.9232 29.9052 29.9232 30.6863 29.1421L43.4142 16.4142ZM2 17L42 17L42 13L2 13L2 17Z"
        fill={`${isHovered ? "#1C6EDA" : "white"}`}
        style={{ transition: "fill 0.3s ease" }}
      />
    </svg>
  );
};

export default Arrow;
