import MainAccordion from "./MainAccordion/MainAccordion";
import "./MainFAQ.css";

const accordionItems = [
  {
    title: "Що саме входить в вартість проєкту?",
    content: "В вартість проєкту входить бріфінг, UX аналітика, аналіз цільової аудиторії вашого бізнесу та її потреб, розробка дизайн-концепції відповідно до стилю вашої компанії та потреб ЦА, підготовка макетів всіх сторінок та затвердження їх з вами, розробка макету з використанням усіх сучасних технологій та адаптація на усі потрібні види девайсів.",
  },
  {
    title: "Які гарантії, що сайт буде добре конвертити?",
    content:
      "З точки зору дизайну: проводиться глибока аналітика вашого продукту, готується макет, який найкраще презентує ваш продукт саме для вашої ЦА. З точки зору програмування: ми не використовую жодних конструкторів, пишемо з урахуванням сучасних тенденцій. Це дозволяє отримати ідеально оптимізований сайт, що в свою чергу дасть вам значну знижку на просування.",
  },
  {
    title: "Як проходить коммунікація з вами?",
    content: `У вас буде проджект-менеджер, який супроводжуватиме вас на всіх етапах. Він буде контролювати розробку вашого проєкту та затверджувати всі етапи з вами. Він обговорюватиме всі нюанси з вами на зрозумілій вам мові, а потім передаватиме на технічній команді розробників. Зв’язок проходить в будь-якому зручному для вас месенджері та по телефону.`,
  },
  {
    title: "Як довго триватиме робота над моїм проєктом?",
    content: `Кожен проєкт для нас унікальний, тож ми не зможемо назвати чіткий термін до проведення бріфінгу. Все залежить від складності проєкту та обсягу роботи. В середньому, робота над більшістю сайтів триває 2-3 тижні. Ми будемо вас інформувати про всі етапи розробки і затверджувати їх з вами.`,
  },
  {
    title: "Як визначитись, який саме сайт найкраще підійде для мого бізнесу?",
    content: `Ми розуміємо, що для вас сайт - це вирішення бізнес-задач. Тож, проєктуючи його, ми намагаємось не отримати абстрактну дизайн-винагороду, яка не несе для вас ніякої цінності. Ми прагнемо вирішити задачу вашого бізнесу. Якщо ви не знаєте який саме сайт найкраще з цим впорається - давайте обговоримо ваш проєкт. Консультація безкоштовна.`,
  },
  {
    title: "Чи надаєте ви послуги технічної підтримки після запуску сайту?",
    content: `На етапі розробки, ми тестуємо та перевіряємо все, що теоретично може бути несправним і викликати баги, тож зазвичай їх немає. Але зрозуміло, що від цього ніхто не застрахований. Якщо на вашому сайті буде щось не так - ми в той же день це виправимо і зробимо це безкошкоштовно.`,
  },
  {
    title: "Як ви вирішуєте питання безпеки веб-сайту та захисту від зламу?",
    content: `Ми завжди використовуємо лише найсучасніші сітьові протоколи, SSL сертифікат і захист веб сайтів. SSL сертифікат є гарантією безпечного підключення до сайту та надається безкоштовно. Захист від атак забезбечує надійна хостинг панель.`,
  },
];

function MainFAQ() {
  return (
    <div className="MainFAQ container">
      <h2 className="main__faq-title text-title-4">Найпоширеніші питання</h2>
      <MainAccordion faqItems={accordionItems}/>
    </div>
  );
}

export default MainFAQ;
