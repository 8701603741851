import React, { useState, useEffect } from "react";
import "./FormSucsessful.css";
import catImage from "../../assets/images/response-image.webp";
import { useSelector } from "react-redux";
import { setClearIsFormSended } from "../../redux/CalculatorForm/CalculatorFormSlice";
import { useDispatch } from "react-redux";
import closeIcon from '../../assets/images/plusIcon.webp'

function FormSucsessful() {
  const response = useSelector((state) => state.calculatorForm.isFormSended);
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  let timeoutId;

  useEffect(() => {
    setIsActive(response);

    if (isActive) {
      timeoutId = setTimeout(() => {
        dispatch(setClearIsFormSended());
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [response, isActive, dispatch]);

  const handleCloseForm = () => {
    dispatch(setClearIsFormSended());
  }

  return (
    <div className={`form__sucsessful-container ${isActive ? "active" : ""}`}>
        <img onClick={handleCloseForm} src={closeIcon} alt="закрити повідомлення про успішну відправку форми" className="form__close-icon" />
      <div className="form__sucsessful">
        <div className="responce__elipse"></div>
        <div className="response__texts">
          <p className="response__title text-title-3">
            Форма успішно відправлена!
          </p>
          <p className="response__description text-base-r">
            Дякуємо за довіру! ми зв’яжемося з вами найближчим часом.
          </p>
        </div>
        <img
          src={catImage}
          alt="фото кота-чародія"
          className="response__image"
        />
      </div>
    </div>
  );
}

export default FormSucsessful;
