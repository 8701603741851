import { configureStore } from "@reduxjs/toolkit";
import CalculatorFormSlice from "./CalculatorForm/CalculatorFormSlice";
import ShortCallbackFormSlice from "./ShortCallbackFormSlice/ShortCallbackFormSlice";

const store = configureStore({
  reducer: {
    calculatorForm: CalculatorFormSlice,
    shortModalForm: ShortCallbackFormSlice,
  },
});

export default store;
