import React, { useState } from "react";
import "./CalculatorStep.css";

const LandingValues = [
  ["До 5 блоків", "5-10 блоків", "більше 10 блоків"],
  ["Готова концепція", "Готова структура", "Потрібно розробляти з 0"],
  ["Так, потрібен редизайн", "Потрібно розробляти з 0"],
  ["Є все, що потрібно", "Є лише частково", "Немає нічого"],
  ["Потрібна 1 мова", "Потрібно 2 мови", "Потрібно 3 і більше мов"],
];

const CorporateValues = [
  ["До 5 сторінок", "5-7 сторінок", "більше 7 сторінок"],
  ["Готова концепція", "Готова структура", "Потрібно розробляти з 0"],
  ["Так, потрібен редизайн", "Потрібно розробляти з 0"],
  ["Є все, що потрібно", "Є лише частково", "Немає нічого"],
  ["Потрібна 1 мова", "Потрібно 2 мови", "Потрібно 3 і більше мов"],
];

const CommerceValues = [
  ["До 30 позицій", "30-100 позицій", "більше 100 позицій"],
  ["Готова концепція", "Готова структура", "Потрібно розробляти з 0"],
  ["Так, потрібен редизайн", "Потрібно розробляти з 0"],
  ["Є все, що потрібно", "Є лише частково", "Немає нічого"],
  ["Потрібна 1 мова", "Потрібно 2 мови", "Потрібно 3 і більше мов"],
];

function CalculatorStep({ type, step, onChoiceSelected, savedAnswers }) {
    const [selectedChoices, setSelectedChoices] = useState(savedAnswers || Array.from({ length: 5 }, () => null));
  
    let values = [];
    if (type === "Лендінг") {
      values = LandingValues[step - 1];
    } else if (type === "Корпоративний") {
      values = CorporateValues[step - 1];
    } else if (type === "Інтернет-магазин") {
      values = CommerceValues[step - 1];
    }
  
    const handleChoice = (choice) => {
      setSelectedChoices((prevChoices) => {
        const newChoices = [...prevChoices];
        newChoices[step - 1] = choice;
        return newChoices;
      });
      onChoiceSelected(choice);
    };
  
    return (
      <div className="calculator__questions-other">
        {values.map((item, itemIdx) => (
          <button
            onClick={() => handleChoice(item)}
            className={`calculator__button-other ${
              selectedChoices[step - 1] === item ? "selected" : ""
            }  text-base-r`}
            key={itemIdx}
          >
            {item}
          </button>
        ))}
      </div>
    );
  }
  

export default CalculatorStep;
