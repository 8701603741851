import './CallbackForm.css'
import MainForm from './MainForm/MainForm';

function CallbackForm() {
    return (
        <section className='callback__form-section'>
            <div className="callback__form-wrapper container">
                <div className="callback__form-elipse"></div>
                <div className="callback__form-texts">
                    <h2 className='callback__form-title text-title-4'>Обговоримо ваш проєкт<span className='question__mark text-base-r'>?</span></h2>
                    <p className='callback__form-description text-base-r'>Ми підбираємо індивідуальні умови та схеми роботи під конкретний проєкт, продукт і набір ІТ-систем.</p>
                    <p className='callback__form-description text-base-r'>Зв’яжіться з нами і ми дамо розгорнуті відповіді на будь-яке ваше питання. Давайте обговоримо всі деталі вашого проєкту! </p>
                    <p className='callback__form-description text-base-r'>Консультація безкоштовна*</p>
                </div>
                <MainForm />
            </div>
        </section>
    )
}

export default CallbackForm;