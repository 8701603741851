import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Card.css";

const Card = ({ title, description, term, price, link }) => {
  const [shadow, setShadow] = useState({
    boxShadow:
      "0px 0px 3px rgba(0, 0, 0, 0.051), 0px 0px 7.2px rgba(0, 0, 0, 0.073), 0px 0px 13.6px rgba(0, 0, 0, 0.09), 0px 0px 24.3px rgba(0, 0, 0, 0.107), 0px 0px 45.5px rgba(0, 0, 0, 0.129), 0px 0px 109px rgba(0, 0, 0, 0.18)",
    transform: "scale(1.0)",
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setPosition({ x, y });

    const offsetX = x / rect.width;
    const offsetY = y / rect.height;

    const limits = 15.0;
    const rotateY = offsetX * (limits * 2) - limits;
    const rotateX = offsetY * (limits * 2) - limits;

    const shadowOffsetX = offsetX * 32 - 16;
    const shadowOffsetY = offsetY * 32 - 16;

    setShadow({
      boxShadow: `${(1 / 6) * -shadowOffsetX}px ${
        (1 / 6) * -shadowOffsetY
      }px 3px rgba(0, 0, 0, 0.051),
        ${(2 / 6) * -shadowOffsetX}px ${
        (2 / 6) * -shadowOffsetY
      }px 7.2px rgba(0, 0, 0, 0.073),
        ${(3 / 6) * -shadowOffsetX}px ${
        (3 / 6) * -shadowOffsetY
      }px 13.6px rgba(0, 0, 0, 0.09),
        ${(4 / 6) * -shadowOffsetX}px ${
        (4 / 6) * -shadowOffsetY
      }px 24.3px rgba(0, 0, 0, 0.107),
        ${(5 / 6) * -shadowOffsetX}px ${
        (5 / 6) * -shadowOffsetY
      }px 45.5px rgba(0, 0, 0, 0.129),
        ${-shadowOffsetX}px ${-shadowOffsetY}px 109px rgba(0, 0, 0, 0.18)`,
      transform: `perspective(1000px) rotateX(${-rotateX}deg) rotateY(${rotateY}deg)`,
    });
  };

  const handleMouseLeave = () => {
    setPosition({ x: 0, y: 0 });

    setShadow({
      boxShadow:
        "0px 0px 3px rgba(0, 0, 0, 0.051), 0px 0px 7.2px rgba(0, 0, 0, 0.073), 0px 0px 13.6px rgba(0, 0, 0, 0.09), 0px 0px 24.3px rgba(0, 0, 0, 0.107), 0px 0px 45.5px rgba(0, 0, 0, 0.129), 0px 0px 109px rgba(0, 0, 0, 0.18)",
      transform: "scale(1.0)",
    });
  };
  const isMobile = window.innerWidth <= 1199;

  return (
    <div
      className="card"
      style={shadow}
      onMouseMove={isMobile ? null : handleMouseMove}
      onMouseLeave={isMobile ? null : handleMouseLeave}
    >
      <div className="glare-container">
        <h3 className="card__title text-title-3">{title}</h3>
        <p className="card__description text-base-r">{description}</p>
        <p className="card__term text-base-r">{term}</p>
        <p className="card__price text-base-r">{price}</p>
        <Link className="card__button text-base-m" to={link}>
          Детальніше
        </Link>
        {isMobile ? (
          ""
        ) : (
          <div
            className="card__glare"
            style={{
              left: position.x + "px",
              top: position.y + "px",
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Card;
