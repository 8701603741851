import MainStep from './MainStep/MainStep';
import './MainWhyUs.css'

const stepsArr = [
    {
        number: 1,
        title: "Прозоре ціноутворення",
        description: "Ми виносимо Комерційну Пропозицію з фінальною вартістю проекту та прописуємо вартість в Договорі, жодних “підводних каменів”.",
        delay: .15
    },
    {
        number: 2,
        title: "Окремий Project manager",
        description: "За кожним проектом закріплюється окремий Project Manager, який завжди на зв’язку з Вами для вирішення будь-яких питань.",
        delay: .3
    },
    {
        number: 3,
        title: "Готове бізнес-рішення",
        description: "Результатом нашої роботи є повністю готовий сайт, який цього ж дня може приносити Вам перших клієнтів. Нічого не потрібно переробляти.",
        delay: .45
    },
    {
        number: 4,
        title: "Тільки сучасні технології",
        description: "Розробляємо використовуючи тільки сучасні технології. Наші сайти відповідають усім стандартам якості та отримують найвищі метрики від Google.",
        delay: .6
    },
    {
        number: 5,
        title: "Індивідуальний підхід",
        description: "Аналізуємо всі аспекти Вашої компанії. Підбираємо структуру, стилістику та формат, які найкраще виконуватимуть задачі саме Вашого бізнесу.",
        delay: .75
    },
    {
        number: 6,
        title: "Довічна гарантія якості",
        description: "За умови нашої підтримки, ми даємо довічну гарантію на сайт. Навіть якщо виникнуть якісь помилки - виправляємо негайно і безкоштовно.",
        delay: .9
    },
]

const MainWhyUs = () => {
  return (
    <section className='main__why-us-section'>
      <div className="why__us-elipse-one-main"></div>
        <h2 className="main__why-us-title text-title-4">6 причин обрати <span className='text-title-r alice-small'><span className='brand-color'>W</span>eb <span className='brand-color'>W</span>izard</span></h2>
        <div className="main__why-us-wrapper">
        {stepsArr.map((step, idx) => {
            return (
              <MainStep
                key={idx}
                stepTitle={step.title}
                stepDescription={step.description}
                stepNumber={step.number}
                delay={step.delay}
              />
            );
          })}
        </div>

    </section>
  )
}

export default MainWhyUs