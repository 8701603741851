import React, { useState, useEffect } from "react";
import "./FormRejected.css";
import catImage from "../../assets/images/response-image.webp";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import closedIcon from '../../assets/images/plusIcon.webp'
import { setClearIsFormFailed } from "../../redux/CalculatorForm/CalculatorFormSlice";

function FormRejected() {
  const response = useSelector((state) => state.calculatorForm.isFormFaild);
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  let timeoutId;

  useEffect(() => {
    setIsActive(response);

    if (isActive) {
      timeoutId = setTimeout(() => {
        dispatch(setClearIsFormFailed());
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [response, isActive, dispatch]);

  const handleCloseForm = () => {
    dispatch(setClearIsFormFailed());
  }

  return (
    <div className={`form__rejected-container ${isActive ? "active" : ""}`}>
        <img onClick={handleCloseForm} src={closedIcon} alt="закрити повідомлення про успішну відправку форми" className="form__close-icon" />
      <div className="form__rejected">
        <div className="responce__elipse-rejected"></div>
        <div className="response__texts">
          <p className="response__title text-title-3">
           {"Упс... Щось пішло не так :("}
          </p>
          <p className="response__description-rejected text-base-r">
            Скоріш за все - це помилка серверу. Спробуйте ще раз, а краще, подзвоніть.
          </p>
        </div>
        <img
          src={catImage}
          alt="фото кота-чародія"
          className="response__image"
        />
      </div>
    </div>
  );
}

export default FormRejected;
