import "./Landing.css";
import LandingSteps from "./LandingSteps/LandingSteps";
import landingCoverImage from "../../assets/images/landingCoverImage.webp";
import landingCoverImageMobile from "../../assets/images/landing-mobile-mockup.webp";
import LandingWhyUs from "./LandingWhyUs/LandingWhyUs";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";

function Landing() {
  const isMobile = window.innerWidth <= 1199;
  const ref = useRef(null);
  const coverImgRef = useRef(null);
  const isInView = useInView(ref, { once: true });
  const coverControls = useAnimation();
  useEffect(() => {
    if (!isMobile && isInView) {
      coverControls.start("visible");
    }
  }, [isInView, coverControls, isMobile]);

  return (
    <>
      <Helmet>
        <title>Замовити сайт Лендінг під ключ | Web-Wizard</title>
        <meta
          name="description"
          content="Створення лендингу (односторінкового сайту) під ключ у Києві та Україні ✅ Ціни на розробку лендингу у 2024 році 🚀 Замов розробку односторінкового сайту та отримай результат вже за кілька днів 💰"
        />
        <meta property="og:url" content="https://web-wizard.com.ua/landing" />
        <link rel="canonical" href="https://web-wizard.com.ua/landing" />
        <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-M3B2VLR9');        
      `}</script>
      </Helmet>
      <div ref={ref} className="landing__page">
        <div className="service__page-elipse-one"></div>
        <div className="service__page-elipse-two"></div>
        <div className="landing__page-elipse-three"></div>

        <div className="landing__cover">
          <motion.div
            className="landing__cover-texts"
            ref={coverImgRef}
            variants={{
              hidden: { opacity: 0, x: -350 },
              visible: { opacity: 1, x: 0 },
            }}
            initial={isMobile ? "visible" : "hidden"}
            animate={coverControls}
            transition={{ duration: 0.7 }}
          >
            <h1 className="landing__cover-title text-title-4">
              Розробка Лендінгу з <span className="brand-color">W</span>eb
              <span className="brand-color">W</span>izard
            </h1>
            <h2 className="page__price text-title-3">Від 500$</h2>
            <p className="landing__cover-descrption text-base-r">
              Розробляючи Landing page, ми занюрюємось у ваше бізнес середовище,
              щоб зрозуміти ваш продукт, ваші цінності і потреби ваших клієнтів.
            </p>
            <p className="landing__cover-descrption text-base-r">
              Тож, процес розробки обов’язково буде корисним і для вас, адже ви
              побачите свій товар/послугу та біснес з іншої точки зору, в
              процесі поглянете на аналіз веб-сайтів найближчих конкурентів та
              лідерів у вашому секторі, на їх підхід до позиціонування і подачі
              свого продукту. Будете залучені в процес проєктування сайту, який
              сподобається не тільки вам, а, що найголовніше, вашим клієнтам,
              адже ми розуміємо, що саме ви найкраще знаєте, як подати свій
              продукт клієнту. Нижче ми розкриємо магію розробки landing page і
              покажемо як це проходить саме у нас...
            </p>
          </motion.div>
          <motion.img
            className="landing__cover-image"
            src={isMobile ? landingCoverImageMobile : landingCoverImage}
            alt="приклад лендінг сторінки сайту спортивної зали."
            ref={coverImgRef}
            variants={{
              hidden: { opacity: 0, x: 350 },
              visible: { opacity: 1, x: 0 },
            }}
            initial={isMobile ? "visible" : "hidden"}
            animate={coverControls}
            transition={{ duration: 0.7 }}
          />
        </div>
        <LandingSteps />
        <LandingWhyUs />
      </div>
      {/* Код Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          title="Google Tag Manager"
          src="https://www.googletagmanager.com/ns.html?id=GTM-M3B2VLR9"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
    </>
  );
}

export default Landing;
