import { useDispatch } from "react-redux";
import "./StepsForm.css";
import InputMask from "react-input-mask";
import { useState } from "react";
import {
  setIsFormFailed,
  setIsFormSended,
} from "../../../../../redux/CalculatorForm/CalculatorFormSlice";

function StepsForm({ answears }) {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [nameError, setNameError] = useState(false);
  const [CompanyError, setCompanyError] = useState(false);
  const [PhoneError, setPhoneError] = useState(false);

  const handlePhoneNumberChange = (e) => {
    setUserPhone(e.target.value);
    setPhoneError(false);
  };

  const handleCompanyChange = (e) => {
    setUserCompany(e.target.value);
    setCompanyError(false);
  };

  const handleNameChange = (e) => {
    setUserName(e.target.value);
    setNameError(false);
  };

  const sendTelegramMessage = async (message) => {
    const TELEGRAM_BOT_TOKEN = "6532120427:AAEtZkK1QqTSwNYt0mB_VnS4Mf7BdWa62Nk";
    const TELEGRAM_CHAT_ID = "-1002051239118";
    const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: TELEGRAM_CHAT_ID,
          text: message,
        }),
      });

      if (response.ok) {
        dispatch(setIsFormSended(true));
      } else {
        dispatch(setIsFormFailed(true));
      }
    } catch (error) {
      dispatch(setIsFormFailed(true));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let nameErrorFlag = false;
    let companyErrorFlag = false;
    let phoneErrorFlag = false;

    if (userName.length < 1) {
      setNameError(true);
      nameErrorFlag = true;
    } else {
      setNameError(false);
    }
    if (userCompany.length < 1) {
      setCompanyError(true);
      companyErrorFlag = true;
    } else {
      setCompanyError(false);
    }

    if (userPhone && !userPhone.includes("_")) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
      phoneErrorFlag = true;
    }

    if (!nameErrorFlag && !companyErrorFlag && !phoneErrorFlag) {
      const message = `\u{1F408}КОЖАНЫЙ!!!\u{1F408}\nИмя: ${userName}\nКомпания: ${userCompany}\nНомер телефона: ${userPhone}\n\u{2796}\u{2796}\u{2796}\u{2796}\u{2796}\u{2796}\u{2796}\u{2796}\u{2796}\u{2796}\u{2796}\u{2796}\u{2796}\nОтветы на вопросы:\nОбъем: ${answears[0]}\nСтадия: ${answears[1]}\nТекущий сайт: ${answears[2]}\nМедиа: ${answears[3]}\nЯзыки: ${answears[4]}`;

      await sendTelegramMessage(message);
      setUserName("");
      setUserCompany("");
      setUserPhone("");
    }
  };
  console.log(answears);

  return (
    <form onSubmit={handleSubmit} className="calculator__form">
      <input
        placeholder="Ім’я"
        value={userName}
        onChange={handleNameChange}
        className={`calculator__input${nameError ? "-error" : ""} text-base-r`}
        type="text"
      />
      <input
        placeholder="Компанія"
        value={userCompany}
        onChange={handleCompanyChange}
        className={`calculator__input${
          CompanyError ? "-error" : ""
        } text-base-r`}
        type="text"
      />
      <InputMask
        mask="+380 (99) 999-99-99"
        value={userPhone}
        onChange={handlePhoneNumberChange}
        placeholder="Номер телефону"
        onFocus={(e) => e.target.setSelectionRange(6, 6)}
        onBlur={(e) => {
          if (e.target.value === "+380 ()") {
            setUserPhone("");
          }
        }}
        className={`calculator__input${PhoneError ? "-error" : ""} text-base-r`}
        type="phone"
      />
      <button
        className="steps__form-button text-title-3"
        onSubmit={handleSubmit}
      >
        Відправити
      </button>
    </form>
  );
}

export default StepsForm;
