import './AboutUsSlider.css';
import AboutUsSliderCard from './AboutUsSliderCard/AboutUsSliderCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

import sliderImageOne from '../../../assets/images/slider-image-1.webp';
import sliderImageTwo from '../../../assets/images/slider-image-2.webp';
import sliderImageThree from '../../../assets/images/slider-image-3.webp';
import sliderImageFour from '../../../assets/images/slider-image-4.webp';
import sliderImageFive from '../../../assets/images/slider-image-5.webp';
import sliderImageSix from '../../../assets/images/slider-image-6.webp';
import sliderImageSeven from '../../../assets/images/slider-image-7.webp';


const sliderImages = [
  { src: sliderImageOne, title: "CEO | Dev Head", description: "Владислав" },
  { src: sliderImageTwo, title: "CEO | Project M.", description: "Владислав" },
  { src: sliderImageThree, title: "Ux-ui designer", description: "Дарія" },
  { src: sliderImageFour, title: "Ux-ui designer", description: "Вікторія" },
  { src: sliderImageFive, title: "Back-end dev", description: "Андрій" },
  { src: sliderImageSix, title: "Front-end dev", description: "Марина" },
  { src: sliderImageSeven, title: "Front-end dev", description: "Ярослав" },
  { src: sliderImageOne, title: "CEO | Dev Head", description: "Владислав" },
  { src: sliderImageTwo, title: "CEO | Project M.", description: "Владислав" },
  { src: sliderImageThree, title: "Ux-ui designer", description: "Дарія" },
  { src: sliderImageFour, title: "Ux-ui designer", description: "Вікторія" },
  { src: sliderImageFive, title: "Back-end dev", description: "Андрій" },
  { src: sliderImageSix, title: "Front-end dev", description: "Марина" },
  { src: sliderImageSeven, title: "Front-end dev", description: "Ярослав" },
];


function AboutUsSlider() {
  const isMobile = window.innerWidth <= 1199;

    return (
      <div className="AboutUsSlider">
        <Swiper
          slidesPerView={isMobile? 2 : 5}
          loop={true}
          navigation={isMobile ? false : true}
          modules={[Navigation]}
          className="mySwiper"
        >
          {sliderImages.map((slide, idx) => (
            <SwiperSlide key={idx}>
              <AboutUsSliderCard
                src={slide.src}
                title={slide.title}
                description={slide.description}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }
  
  export default AboutUsSlider;
