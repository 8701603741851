import './AboutUsValue.css'


function AboutUsValue({count, title, description, mobileCount}) {

    return (
    <div className="aboutUs__value">
        <div className="aboutUs__value-count text-base-m">{count}</div>
        <div className="aboutUs__value-texts">
        <h3 className="aboutUs__value-title text-title-3"><span className='about__us-mobile-count brand-color'>{mobileCount}</span>{title}</h3>
        <p className="aboutUs__value-description text-base-r">{description}</p>
        </div>
    </div>
    )
}

export default AboutUsValue