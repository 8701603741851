import './ECommerceSteps.css'
import ecommerceFirstImage from '../../../assets/images/landing-step-one.webp'
import ecommerceSecondImage from '../../../assets/images/landing-step-two.webp'
import ecommerceThirdImage from '../../../assets/images/ecommerce-step-image-three.webp'
import ecommerceFourImage from '../../../assets/images/ecommerce-step-image-four.webp'
import ecommerceFivesImage from '../../../assets/images/landing-step-five.webp'
import ecommerceSixImage from '../../../assets/images/ecommerce-step-image-six.webp'
import Step from '../../../components/Step/Step'
import SliderStepResult from '../../../components/SliderStepResult/SliderStepResult'
import EcommerceStepsProgress from '../ECommerceStepsProgress/ECommerceStepsProgress'
import ecommeceSliderOne from '../../../assets/images/ecommerce-slider-one.webp'
import ecommeceSliderTwo from '../../../assets/images/ecommerce-slider-two.webp'
import ecommeceSliderThree from '../../../assets/images/ecommerce-slider-three.webp'
import ecommeceSliderFour from '../../../assets/images/ecommerce-slider-four.webp'
import ecommeceSliderFive from '../../../assets/images/ecommerce-slider-five.webp'

const sliderImages = [ecommeceSliderOne, ecommeceSliderTwo, ecommeceSliderThree, ecommeceSliderFour, ecommeceSliderFive]


function ECommerceSteps() {
    const isMobile = window.innerWidth <= 1199;

    return (
    <div className="ecommerce__steps">
          <div className="step__elipse-one"></div>
        <div className="step__elipse-two"></div>
        <div className="step__elipse-three"></div>
        <div className="step__elipse-four"></div>
        <div className="step__elipse-five"></div>
        <div className="step__elipse-six"></div>
        <Step src={ecommerceFirstImage} stepNumber={"001"} title={"Брифінг"} description={"На цьому етапі нам важливо почути всі ваші запити і побажання. Ми зідзвонюємось в будь якому зручному для вас мессенджері, дивимось та аналізуємо сайти-референси та сайти ваших конкурентів, обговорюємо який саме продукт ми маємо зробити в підсумку і заповнюємо технічне завдання."} reverse={isMobile ? true : false} isItFirstStep={true} />
        <Step src={ecommerceSecondImage} stepNumber={"002"} title={"Аналіз ринку"} description={"Ми аналізуємо веб сайти ваших конкурентів та лідерів сегменту ринку, дивимось на що саме вони роблять акцент, як просувають та позіціонують свою компанію. Аналізуємо яку саме проблему споживача вирішує ваша компанія, та які саме аспекти ваших товарів є найважливішими для ЦА, щоб зробити на них акцент, а які, можливо, слід приховати."} reverse={true} isItFirstStep={false} />
        <Step src={ecommerceThirdImage} stepNumber={"003"} title={"Дизайн-концепція"} description={"Ми підготовлюємо дизайн головного екрану в стилі вашого бренду, в рамках технічного завдання, відповідно до потреб ЦА. Презентуємо вам концепцію та пояснюємо, чому було втілене те чи інше дизайн-рішення. Якщо потрібно, приймаємо правки від вас і перероблюємо те, що не сподобалося. Ми не використовуємо жодних кострукторів, тож сайт буде унікальним, адже створюється саме під стиль вашої компанії."} reverse={isMobile ? true : false} isItFirstStep={false} />
        <Step src={ecommerceFourImage} stepNumber={"004"} title={"Дизайн всіх сторінок"} description={"На цьому етапі нам потрібно створити структуру, яка буде зрочною, інтуітивно зрозумілою і сприятиме комфортному пошуку потрібних товарів. Ми підготовлюємо дизайн всього проєкту, адаптуємо його на всі потрібні види пристроїв. Презентуємо вам готовий макет, пояснюємо як саме він презентуватиме вашу компанію і сприятиме залученню нових клієнтів. Затверджуємо його з вами та приймаємо останні правки."} reverse={true} isItFirstStep={false} />
        <Step src={ecommerceFivesImage} stepNumber={"005"} title={"Розробка"} description={"Ми розробляємо затвердженний з вами макет з урахуванням всіх сучасних вимог пошукових систем. Оптимізуємо його для всіх браузерів та пристроїв. Ми не використовуємо жодних конструкторів сайтів (tilda, wordpress и тд.). Завдяки тому, що код пишеться вручну під ваші потреби, сайт отримує максимальні показники продуктивності та оптимізаці в усіх метриках Google, що зменшує вартість його просування для вас."} reverse={isMobile ? true : false} isItFirstStep={false} />
        <Step src={ecommerceSixImage} stepNumber={"006"} title={"Тестування"} description={"Ми встановлюємо сайт на хост-панель і тестуємо усі можливі сценарії поведінки користувача. Ще раз перевіряємо оптимізацію в усіх браузерах та на всіх пристроях, виправляємо баги (якщо вони є). Обираючи нас, ви отримаєте готовий сайт, з яким можна відразу працювати, без необхідності щось виправляти."} reverse={true} isItFirstStep={false} />
        <SliderStepResult title={"В результаті"} description={"Оптимізований та ефективний інтернет-магазин, який ідеально презентує ваші товари і сприяє залученню клієнтів. Ми гарантуємо високу якість роботи і залишаємося з вами на зв'язку після завершення проєкту. Якщо виникнуть будь-які питання чи потреба в допомозі, ми завжди тут, готові надати оперативну підтримку."} imageList={sliderImages}/>
        <EcommerceStepsProgress />
    </div>
    )
}

export default ECommerceSteps;