import "./MainAbout.css";
import mockup from "../../../assets/images/mocupMain.webp";
import mockupMobile from "../../../assets/images/mocupMain-mobile.webp";
import { Link } from "react-router-dom";
import { ABOUT_ROUTE } from "../../../utils/constants";

function MainAbout() {
  return (
    <div className="main__about container">
      <div className="main__about-texts">
        <div className="main__about-title text-title-4">Хто ми такі</div>
        <div className="main__about-description text-base-r">
          Ми — творча команда WebWizard, де технічна майстерність переплітається
          з чарівністю творчості.
        </div>
        <div className="main__about-description text-base-r">
          Наші проєкти — це не лише веб-сайти, це унікальні платформи, які
          допомагають брендам рости та процвітати в цифровому світі. За роки
          роботи над різноманітними проєктами ми створили свою власну історію
          успіху...
        </div>
        <Link className="main__about-link text-base-r" to={ABOUT_ROUTE}>
        Дізнатися більше &#61;&#61;&gt;
        </Link>
      </div>
      <div className="main__elipse-2"></div>
      <img
        className="main__mockup"
        src={mockup}
        alt="Мокап, який показує приклади розробки сайтів компанією Web Wizards"
      />
      <img
        className="main__mockup-mobile"
        src={mockupMobile}
        alt="Мокап, який показує приклади розробки сайтів компанією Web Wizards"
      />
    </div>
  );
}

export default MainAbout;
