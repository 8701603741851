import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isActive: false
};

const ShortCallbackFormSlice = createSlice({
  name: "ShortModal",
  initialState,
  reducers: {
   setIsActive: (state, action) => {
    state.isActive = true
   },
   setIsClose: (state, action) => {
    state.isActive = false
   }
  },
});

export const {
  setIsActive,
  setIsClose
} = ShortCallbackFormSlice.actions;
export default ShortCallbackFormSlice.reducer;
