import "./Benefite.css"

function Benefite({icon, title, description}) {
    return (
        <div className="benefite">
            <img className="benefite__icon" src={icon} alt={icon} />
            <p className="benefite__title text-title-3">{title}</p>
            <p className="benefite__description text-base-r">{description}</p>
        </div>
    )
}

export default Benefite;