import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nameError: false,
  companyError: false,
  phoneError: false,
  isFormSended: false,
  isFormFaild: false,
  isFormActive: false,
  isBurgerActive: false,
  isMenuOpen: false,
};

const CalculatorFormSlice = createSlice({
  name: "CalculatorForm",
  initialState,
  reducers: {
    setNameError: (state, action) => {
      state.nameError = action.payload;
    },
    setCompanyError: (state, action) => {
      state.companyError = action.payload;
    },
    setPhoneError: (state, action) => {
      state.phoneError = action.payload;
    },
   
    setIsFormSended: (state) => {
      state.isFormSended = true;
    },
    setIsFormFailed: (state, action) => {
      state.isFormFaild = action.payload;
    },
    setClearIsFormSended: (state) => {
      state.isFormSended = false;
    },
    setClearIsFormFailed: (state) => {
      state.isFormFaild = false;
    },
    setClearUserAnswears: (state) => {
      state.userAnswears = [];
    },
    isFormActive: (state) => {
      state.isFormActive = !state.isFormActive;
    },
    isBurgerActive: (state) => {
      state.isBurgerActive = !state.isBurgerActive;
    },
    setBurgerClose: (state) => {
      state.isBurgerActive = false
    },
    setisMenuToggle: (state) => {
      state.isMenuOpen = !state.isMenuOpen
    },
    setisMenuClose: (state) => {
      state.isMenuOpen = false
    }
  },
});

export const {
  setNameError,
  setCompanyError,
  setPhoneError,
  setUserAnswears,
  setIsFormSended,
  setIsFormFailed,
  setClearIsFormSended,
  setClearIsFormFailed,
  isFormActive,
  isBurgerActive,
  setBurgerClose,
  setisMenuToggle,
  setisMenuClose,
} = CalculatorFormSlice.actions;
export default CalculatorFormSlice.reducer;
