import './BurgerMenu.css'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { ABOUT_ROUTE, COMMERCE_ROUTE, CORPORATE_ROUTE, LANDING_ROUTE } from '../../utils/constants';
import { isBurgerActive } from '../../redux/CalculatorForm/CalculatorFormSlice';
import { useEffect } from 'react';


function BurgerMenu() {
    const dispatch = useDispatch();
    const isActive = useSelector((state) => state.calculatorForm.isBurgerActive);
    const handleClick = () => {
        dispatch(isBurgerActive())
    }

    useEffect(() => {
        const body = document.body;
        if (isActive) {
          body.style.overflow = "hidden";
        } else {
          body.style.overflow = "auto";
        }
    
        return () => {
          body.style.overflow = "auto";
        };
      }, [isActive]);


    return (
        <div className={`burger__menu ${isActive ? "active" : ""}`}>
            <div className="burger__wrapper">
            <div className="burger__main">
                <div className="burger__ellipse"></div>
                <NavLink onClick={handleClick} to={LANDING_ROUTE} className={"text-base-m"}>Лендінг</NavLink>
                <NavLink onClick={handleClick} to={CORPORATE_ROUTE} className={"text-base-m"}>Корпоратив</NavLink>
                <NavLink onClick={handleClick} to={COMMERCE_ROUTE} className={"text-base-m"}>Інтернет-магазин</NavLink>
                <NavLink onClick={handleClick} to={ABOUT_ROUTE} className={"text-base-m"}>Про нас</NavLink>
            </div>
            <div className="burger__overlay"></div>
            </div>
        </div>
    )
}

export default BurgerMenu;