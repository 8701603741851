import "./AboutUs.css";
import coverImage from "../../assets/images/AboutUsCoverImage.webp";
import uniqImage from "../../assets/images/aboutUsUniqImage.webp";
import AboutUsValue from "./AboutUsValue/AboutUsValue";
import AboutUsSlider from "./AboutUsSlider/AboutUsSlider";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";

function About_us() {
  const isMobile = window.innerWidth <= 1199;

  const ref = useRef(null);
  const coverImgRef = useRef(null);

  const isInView = useInView(ref, { once: true });

  const coverControls = useAnimation();

  useEffect(() => {
    if (!isMobile && isInView) {
      coverControls.start("visible");
    }
  }, [isInView, coverControls, isMobile]);

  return (
    <>
      <Helmet>
        <title>Ласкаво просимо в WebWizard | Web-Wizard</title>
        <meta
          name="description"
          content="Ласкаво просимо в WebWizard. За роки роботи над різноманітними проєктами ми створили свою власну історію успіху."
        />
        <meta property="og:url" content="https://web-wizard.com.ua/about_us" />
        <link rel="canonical" href="https://web-wizard.com.ua/about_us" />
        <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-M3B2VLR9');        
      `}</script>
      </Helmet>
      <div ref={ref} className="aboutUs__page">
        <div className="about__ellipse-one"></div>
        <div className="aboutUs__cover">
          <div className="about__ellipse-two"></div>
          <motion.div
            ref={coverImgRef}
            variants={{
              hidden: { opacity: 0, x: -350 },
              visible: { opacity: 1, x: 0 },
            }}
            initial={isMobile ? "visible" : "hidden"}
            animate={coverControls}
            transition={{ duration: 0.7 }}
            className="aboutUs__cover-texts"
          >
            <h2 className="aboutUs__cover-title text-title-4">
              Ласкаво просимо в <span className="brand-color">W</span>eb
              <span className="brand-color">W</span>izard
            </h2>
            <p className="aboutUs__cover-descrption text-base-r">
              Ми — команда WebWizard, де технічна майстерність переплітається з
              чарівністю творчості.
            </p>
            <p className="aboutUs__cover-descrption text-base-r">
              Наші проєкти — це не лише веб-сайти, це унікальні платформи, які
              допомагають брендам рости та процвітати в цифровому світі. За роки
              роботи над різноманітними проєктами ми створили свою власну
              історію успіху.
            </p>
          </motion.div>
          <motion.img
            src={coverImage}
            alt="чоловік сидить за комп'ютером і пише код"
            className="aboutUs__cover-image"
            ref={coverImgRef}
            variants={{
              hidden: { opacity: 0, x: 350 },
              visible: { opacity: 1, x: 0 },
            }}
            initial={isMobile ? "visible" : "hidden"}
            animate={coverControls}
            transition={{ duration: 0.7 }}
          />
        </div>
        <div className="aboutUs__uniq">
          <div className="about__ellipse-three"></div>
          <div className="about__ellipse-seven"></div>
          <img
            src={uniqImage}
            alt="фото ноутбука з макетом проєкта"
            className="aboutUs__uniq-image"
          />
          <div className="aboutUs__uniq-texts">
            <h2 className="aboutUs__uniq-title text-title-4">
              Що робить нас <span className="brand-color">унікальними</span>
            </h2>
            <p className="aboutUs__uniq-description text-base-r">
              Кожен сайт — це не лише код і дизайн, але і енергія, яку ми
              вкладаємо в кожен проєкт, щоб забезпечити його унікальність та
              ефективність. Наша команда глибоко вникає в особливості вашого
              бізнесу, надає індивідуальний підхід та повний спектр послуг для
              досягнення ваших цілей в онлайн-світі. Ваш успіх — це і наш успіх,
              і ми завжди тут, щоб забезпечити ваш бренд найвищим рівнем уваги
              та технічної експертизи.{" "}
            </p>
          </div>
        </div>
        <div className="aboutUs__values">
          <div className="aboutUs__values-wraper">
            <div className="about__ellipse-four"></div>
            <h2 className="aboutUs__values-title text-title-4">
              Цінності <span className="brand-color">W</span>eb
              <span className="brand-color">W</span>izard
            </h2>
            <AboutUsValue
              count={"001"}
              mobileCount={"01"}
              title={"Зробити світ краще"}
              description={
                "Ми прагнемо створювати продукти, які будуть зручними в використанні і  привабливими для користувачів, щоб принести в світ трішки краси і магії."
              }
            />
            <AboutUsValue
              count={"003"}
              mobileCount={"03"}
              title={"Постійний розвиток"}
              description={
                "Ми ніколи не зупиняємося на досягнутому і постійно вдосконалюємо свої навички, що впроваджувати їх в усіх наших проєктах."
              }
            />
            <AboutUsValue
              count={"002"}
              mobileCount={"02"}
              title={"Любити те, що робимо"}
              description={
                "Ми створюємо продукти, які є зручним в використанні і якими пишаємося. Щоб ми завжди могли з гордістю відповісти: “Так, це зробили ми”."
              }
            />
            <AboutUsValue
              count={"004"}
              mobileCount={"04"}
              title={"Робити те, ще любимо"}
              description={
                "В основі якості наших продуктів лежить щира любов до своєї справи. Ми розробляємо кожен проєкт, вкладаючи в нього частинку душі."
              }
            />
          </div>
        </div>
        <div className="aboutUs__team">
          <div className="about__ellipse-five"></div>
          <div className="about__ellipse-six"></div>
          <div className="aboutUs__team-texts">
            <div className="aboutUs__team-title text-title-4">Наша команда</div>
            <div className="aboutUs__team-description text-base-r">
              Наша команда - це група талановитих і мотивованих людей, які
              працюють разом, щоб досягти великих успіхів.
            </div>
          </div>
          <AboutUsSlider />
        </div>
      </div>
      {/* Код Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          title="Google Tag Manager"
          src="https://www.googletagmanager.com/ns.html?id=GTM-M3B2VLR9"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
    </>
  );
}

export default About_us;
