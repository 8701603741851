import "./MainStep.css";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

const MainStep = ({ stepTitle, stepDescription, stepNumber, delay }) => {
  const isMobile = window.innerWidth <= 1199;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1, x: 0 },
      }}
      initial={ "hidden"}
      animate={mainControls}
      transition={{ duration: 1, delay: isMobile ? 0 : delay }}
    >
      <div className="step__benefite-main">
        <div className={`step__benefite-number-main mont-b brand-color`}>
          {stepNumber}
        </div>
        <div className="cstep_benefite-texts-main">
          <div className="step__benefite-header-main">
            <div
              className={`step__benefite-number-mobile-main mont-b brand-color`}
            >
              {stepNumber}
            </div>

            <h2 className={`step__benefite-title-main text-title-3`}>
              {stepTitle}
            </h2>
          </div>
          <p className="step__benefite-description-main text-base-r">
            {stepDescription}
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default MainStep;
