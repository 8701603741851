import './LandingSteps.css'
import landingFirstImage from '../../../assets/images/landing-step-one.webp'
import landingSecondImage from '../../../assets/images/landing-step-two.webp'
import landingThirdImage from '../../../assets/images/landing-step-three.webp'
import landingFourImage from '../../../assets/images/landing-step-four.webp'
import landingFivesImage from '../../../assets/images/landing-step-five.webp'
import landingSixImage from '../../../assets/images/landing-step-six.webp'
import Step from '../../../components/Step/Step'
import LandingStepsProgress from '../LandingStepsProgress/LandingStepsProgress';
import SliderStepResult from '../../../components/SliderStepResult/SliderStepResult'
import sliderImageOne from '../../../assets/images/carousel-landing-one.webp'
import sliderImageTwo from '../../../assets/images/carousel-landing-two.webp'
import sliderImageThree from '../../../assets/images/carousel-landing-three.webp'
import sliderImageFour from '../../../assets/images/carousel-landing-four.webp'
import sliderImageFive from '../../../assets/images/carousel-landing-five.webp'
import sliderImageSix from '../../../assets/images/carousel-landing-six.webp'
import sliderImageSeven from '../../../assets/images/carousel-landing-seven.webp'

const sliderImages = [sliderImageOne, sliderImageTwo, sliderImageThree, sliderImageFour, sliderImageFive, sliderImageSix, sliderImageSeven,]


function LandingSteps() {
    const isMobile = window.innerWidth <= 1199;

    return (
    <div className="landing__steps">
        <div className="step__elipse-one"></div>
        <div className="step__elipse-two"></div>
        <div className="step__elipse-three"></div>
        <div className="step__elipse-four"></div>
        <div className="step__elipse-five"></div>
        <div className="step__elipse-six"></div>
        <Step src={landingFirstImage} stepNumber={"001"} title={"Брифінг"} description={"На цьому етапі нам важливо почути всі ваші запити і побажання. Ми зідзвонюємось в будь якому зручному для вас мессенджері, дивимось та аналізуємо сайти-референси і сайти ваших конкурентів, обговорюємо який саме продукт ми маємо зробити в підсумку і заповнюємо технічне завдання."} reverse={isMobile ? true : false} isItFirstStep={true} />
        <Step src={landingSecondImage} stepNumber={"002"} title={"Аналіз ринку"} description={"Ми аналізуємо веб сайти ваших конкурентів та лідерів сегменту ринку, дивимось на що саме вони роблять акцент, як просувають та позіціонують свій продукт. Аналізуємо яку саме проблему споживача вирішує ваш товар/послуга, та які саме аспекти продукту є найважливішими для ЦА, щоб зробити на них акцент, а які, можливо, слід приховати."} reverse={true} isItFirstStep={false} />
        <Step src={landingThirdImage} stepNumber={"003"} title={"Дизайн-концепція"} description={"Ми підготовлюємо дизайн головного екрану в стилі вашого бренду, в рамках технічного завдання, відповідно до потреб ЦА. Презентуємо вам концепцію та пояснюємо, чому було втілене те чи інше дизайн-рішення. Якщо потрібно, приймаємо правки від вас і перероблюємо те, що не сподобалося. Ми не використовуємо жодних кострукторів, тож сайт буде унікальним, адже створюється саме під стиль вашої компанії."} reverse={isMobile ? true : false} isItFirstStep={false} />
        <Step src={landingFourImage} stepNumber={"004"} title={"Дизайн всіх блоків"} description={"На цьому етапі нам потрібно створити структуру сайту, який ідеально впорається з презентацією та продажем вашого продукту. Ми підготовлюємо дизайн всього проєкту та адаптуємо його на всі потрібні види пристроїв. Презентуємо вам готовий макет, пояснюємо як саме він виконуватиме поставлену задачу чи збільшуватиме конверсію. Затверджуємо його з вами та приймаємо останні правки."} reverse={true} isItFirstStep={false} />
        <Step src={landingFivesImage} stepNumber={"005"} title={"Розробка"} description={"Ми розробляємо затвердженний з вами макет з урахуванням всіх сучасних вимог пошукових систем. Оптимізуємо його для всіх браузерів та пристроїв. Ми не використовуємо жодних конструкторів сайтів (tilda, wordpress и тд.). Завдяки тому, що код пишеться вручну під ваші потреби, сайт отримує максимальні показники продуктивності та оптимізаці в усіх метриках Google, що зменшує вартість його просування для вас."} reverse={isMobile ? true : false} isItFirstStep={false} />
        <Step src={landingSixImage} stepNumber={"006"} title={"Тестування"} description={"Ми встановлюємо сайт на хост-панель і тестуємо усі можливі сценарії поведінки користувача. Ще раз перевіряємо оптимізацію в усіх браузерах та на всіх пристроях, виправляємо баги (якщо вони є). Обираючи нас, ви отримаєте готовий сайт, з яким можна відразу працювати, без необхідності щось виправляти."} reverse={true} isItFirstStep={false} />
        <LandingStepsProgress />
        <SliderStepResult title={"В результаті"} description={"Оптимізований та ефективний Landing Page, який ідеально презентує ваш продукт і сприяє його успішному продажу. Ми гарантуємо високу якість роботи і залишаємося з вами на зв'язку після завершення проєкту. Якщо виникнуть будь-які питання чи потреба в допомозі, ми завжди тут, готові надати оперативну підтримку."} imageList={sliderImages}/>
    </div>
    )
}

export default LandingSteps;