import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppRouter from './components/AppRouter/AppRouter';
import { ABOUT_ROUTE, COMMERCE_ROUTE, CORPORATE_ROUTE, LANDING_ROUTE } from './utils/constants';
import { HelmetProvider } from 'react-helmet-async';
import AboutUs from './pages/AboutUs/AboutUs'
import Corporate from './pages/Corporate/Corporate';
import Landing from './pages/Landing/Landing';
import MainPage from './pages/Main_page/MainPage';
import ECommerce from './pages/ECommerce/ECcommerce';

function App() {
  return (
    <HelmetProvider>
    <Router>
      <Routes>
          <Route path="/" element={<AppRouter />}>
            <Route index element={<MainPage />} />
            <Route path={ABOUT_ROUTE} element={<AboutUs />} />
            <Route path={COMMERCE_ROUTE} element={<ECommerce />} />
            <Route path={CORPORATE_ROUTE} element={<Corporate />} />
            <Route path={LANDING_ROUTE} element={<Landing />} />
          </Route>
      </Routes>
    </Router>
    </HelmetProvider>
  );
}

export default App;