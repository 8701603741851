import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import CallbackForm from "../CallbackForm/CallbackForm";
import CallButton from "../CallButton/CallButton";
import CanvasAnimation from "../CanvasAnimation/CanvasAnimation";
import Footer from "../Footer/Footer";
import FormRejected from "../FormRejected/FormRejected";
import FormSucsessful from "../FormSucsessful/FormSucsessful";
import Header from "../Header/Header";
import LetsTalk from "../LetsTalk/LetsTalk";
import ModalForm from "../ModalForm/ModalForm";
import ShortModalForm from "../ShortModalForm/ShortModalForm";
import './AppRouter.css'

function AppRouter() {
  const location = useLocation();
  const { pathname } = useLocation()
  const animationHeight = determineAnimationHeight(location.pathname);
  useEffect(()=> {
    window.scrollTo(0,0);
  }, [pathname])

  return (
    <div className="global__container">
      <ModalForm />
      <BurgerMenu />
      <Header />
      <main className="main">
        <CanvasAnimation height={animationHeight} />
        <Outlet />
      </main>
      <FormSucsessful />
      <FormRejected />
      {/* <CallbackForm /> */}
      <LetsTalk />
      <Footer />
      <CallButton />
      <ShortModalForm />
    </div>
  );
}

function determineAnimationHeight(pathname) {
  const isMobile = window.innerWidth <= 1199;

  switch (pathname) {
    case '/about_us':
      return isMobile ? '120px' : '120px';
    case '/landing':
      return isMobile ? '120px' : '120px';
    case '/corporate':
      return isMobile ? '120px' : '120px'; 
    case '/e-commerce':
      return isMobile ? '120px' : '120px'; 
    default:
      return isMobile ? '295px' : '405px'; 
  }
}


export default AppRouter;

