import './CallButton.css'
import phoneIcon from "../../assets/icons/phone-icon.png";
import { useDispatch } from "react-redux";
import { setIsActive } from '../../redux/ShortCallbackFormSlice/ShortCallbackFormSlice';
import { setBurgerClose, setisMenuClose } from '../../redux/CalculatorForm/CalculatorFormSlice';

const CallButton = () => {
  const dispatch = useDispatch();
  const handleModalOpen = () => {
    dispatch(setIsActive());
    dispatch(setBurgerClose())
    dispatch(setisMenuClose())
  };
  
  return (
    <div onClick={handleModalOpen} className="btn-call">
      <div className="btn-call__ico">
        <img
          src={phoneIcon}
          alt="іконка при натискнні на яку відкриється форма зворотнього зв'язку"
          className="fas fa-phone-alt"
        />
      </div>
    </div>
  );
};

export default CallButton;
